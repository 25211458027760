<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="'Category'"
        [placeholder]="'Search Control Categories'"
        [control]="formGroup?.controls?.controlCategory"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['CONTROL_CATEGORY']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="'Policy Sections'"
        [placeholder]="'Search Policy Sections'"
        [control]="formGroup?.controls?.policySections"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [searchUrl]=""
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['POLICY_BODY_SECTION']"
      >
      </app-target-code-selector>
    </div>

    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="'Citations'"
        [placeholder]="'Search Citations'"
        [control]="formGroup?.controls?.citations"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['CITATION']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Source Of Control"
        [placeholder]="'Enter Source Of Control'"
        [control]="formGroup?.controls?.sourceOfControl"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Source Reference"
        [placeholder]="'Enter Source Reference'"
        [control]="formGroup?.controls?.sourceReference"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Unique Identifier"
        [placeholder]="'Enter Unique Identifier'"
        [control]="formGroup?.controls?.uniqueIdentifier"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    @if (fieldViewMode != 'create') {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="'Authorities'"
          [placeholder]="'Search Authorities'"
          [control]="formGroup?.controls?.authorities"
          [viewMode]="'view'"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
          [targetTypes]="['AUTHORITY_DOCUMENT']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="'Policies'"
          [placeholder]="'Search Policies'"
          [control]="formGroup?.controls?.policies"
          [viewMode]="'view'"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
          [targetTypes]="['POLICY']"
        >
        </app-target-code-selector>
      </div>
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
