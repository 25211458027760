import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, ControlObjectiveDto, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { PolicyBodySectionDataService } from '../../service/data/policy-body-section-data.service';

@Component({
  selector: 'app-control-objective-item-form',
  templateUrl: './control-objective-item-form.component.html',
  styleUrl: './control-objective-item-form.component.scss',
})
export class ControlObjectiveItemFormComponent extends BaseForm<ControlObjectiveDto> implements OnInit {
  lastApprovedUrl;
  constructor(
    public viewModeService: ViewModeService,
    private policySectionsDataService: PolicyBodySectionDataService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.ControlObjective);
    this.lastApprovedUrl = this.policySectionsDataService.lastApprovedUrl;
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    // this.policyBodyForm.patchValue({...data.policyBody});
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      policySections: new FormControl(null),
      citations: new FormControl(null),
      controlCategory: new FormControl(null),
      sourceOfControl: new FormControl(null),
      sourceReference: new FormControl(null),
      uniqueIdentifier: new FormControl(null),
      authorities: new FormControl(null),
      policies: new FormControl(null),
    });
  }
}
